(function ($) {
    "use strict";


    var elementsWithDataHref = document.querySelectorAll('[data-href]');
    elementsWithDataHref.forEach(function(element) {
        var dataHrefValue = element.getAttribute('data-href');
        element.setAttribute('href', dataHrefValue);
    });

    function attachGalleryOnClick(elementId) {
        document.getElementById(elementId).onclick = function (event) {
            event = event || window.event;
            var target = event.target || event.srcElement,
                link = target.closest("a"),
                options = { 
                    index: link, 
                    event: event
                },
                links = document.getElementById(elementId).getElementsByTagName('a');
            blueimp.Gallery(links, options);
        };
    }

    attachGalleryOnClick('links');
    attachGalleryOnClick('links2');
    
// Gif

//   Lazyload gif
const observerForGIF = new IntersectionObserver((entries, observer) => {
entries.forEach((entry) => {
  if (entry.isIntersecting) {
    const picture = entry.target;
    const image = picture.querySelector('.lazyload-gif');

    if (image && image.dataset.src) {
      const sourceTags = picture.querySelectorAll('source');

      // Check if the image has been loaded
      if (image.complete) {
        sourceTags.forEach((source) => {
          if (source.dataset.srcset) {
            source.setAttribute('srcset', source.dataset.srcset);
          }
        });

        image.setAttribute('src', image.dataset.src);
        observer.unobserve(picture);
      }
    }
  }
});
});



var GIFIsLoaded = false;

function lazyLoadGIF() {
if (!GIFIsLoaded) {
    GIFIsLoaded = true;
    const lazyLoadGIFElements = document.querySelectorAll('.block-3img');
    lazyLoadGIFElements.forEach((element) => {
        observerForGIF.observe(element);
    });
}
}

document.addEventListener('DOMContentLoaded', function() {
['click', 'mousemove', 'touchstart'].forEach(function(eventType) {
    window.addEventListener(eventType, lazyLoadGIF, {
    once: true
    });
});

setTimeout(function() {
    lazyLoadGIF();
}, 15000); // Jei naudotojas nesąveikauja, įkelti GIF po 6,5 sekundžių
});




// Lazy load gmap
const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const iframe = document.createElement("iframe");
          
          const attributes = entry.target.attributes;
          for (let i = 0; i < attributes.length; i++) {
            iframe.setAttribute(attributes[i].name, attributes[i].value);
          }
    
          iframe.src = entry.target.dataset.src;
          entry.target.appendChild(iframe);
          observer.unobserve(entry.target);
        }
      });
  });

  const lazyLoadMapElements = document.querySelectorAll(".lazy-load-map");
  lazyLoadMapElements.forEach((element) => {
    observer.observe(element);
  });


  document.addEventListener("touchstart", touchStartHandler, { passive: true });
  document.addEventListener("touchmove", touchMoveHandler, { passive: true });
  document.addEventListener("wheel", wheelHandler, { passive: true });

  function touchStartHandler(event) {
  }

  function touchMoveHandler(event) {
  }

  function wheelHandler(event) {
  }

//   Responsive shipping prices

      // Make it more responsive :)
      const table = document.getElementById('shipping-table-prices');
      const rows = table.getElementsByTagName('tr');
      const citiesAndPrices = [];

      for (let i = 0; i < rows.length; i++) {
         const cells = rows[i].getElementsByTagName('td');
         for (let j = 0; j < cells.length; j++) {
            const data = cells[j].textContent.split(': ');
            const city = data[0];
            const price = data[1];
            citiesAndPrices.push({ city, price });
         }
      }

      function createTable() {
         const viewportWidth = window.innerWidth;
         let columns = 1;

         if (viewportWidth >= 1200) {
            columns = 4;
         } else if (viewportWidth >= 800) {
            columns = 3;
         } else if (viewportWidth >= 600) {
            columns = 2;
         }

         const rows = Math.ceil(citiesAndPrices.length / columns);

         let tableContent = '';
         let counter = 0;

         for (let i = 0; i < rows; i++) {
            tableContent += '<tr>';

            for (let j = 0; j < columns; j++) {
            if (citiesAndPrices[counter]) {
               tableContent += `<td>${citiesAndPrices[counter].city}: ${citiesAndPrices[counter].price}</td>`;
               counter++;
            }
            }

            tableContent += '</tr>';
         }

         table.querySelector('tbody').innerHTML = tableContent;
      }

      createTable();
      window.addEventListener('resize', createTable);

    /*[ Load page ]
    ===========================================================*/
    // $(".animsition").animsition({
    //     inClass: 'fade-in',
    //     outClass: 'fade-out',
    //     inDuration: 1500,
    //     outDuration: 800,
    //     linkElement: '.animsition-link',
    //     loading: true,
    //     loadingParentElement: 'html',
    //     loadingClass: 'animsition-loading-1',
    //     loadingInner: '<div data-loader="ball-scale"></div>',
    //     timeout: false,
    //     timeoutCountdown: 5000,
    //     onLoadEvent: true,
    //     browser: [ 'animation-duration', '-webkit-animation-duration'],
    //     overlay : false,
    //     overlayClass : 'animsition-overlay-slide',
    //     overlayParentElement : 'html',
    //     transition: function(url){ window.location.href = url; }
    // });
    
    /*[ Back to top ]
    ===========================================================*/
    var windowH = $(window).height()/2;

    $(window).on('scroll',function(){
        if ($(this).scrollTop() > windowH) {
            $("#myBtn").css('display','flex');
        } else {
            $("#myBtn").css('display','none');
        }
    });

    $('#myBtn').on("click", function(){
        $('html, body').animate({scrollTop: 0}, 300);
    });



    /*[ Show header dropdown ]
    ===========================================================*/
    $('.js-show-header-dropdown').on('click', function(){
        $(this).parent().find('.header-dropdown')
    });

    var menu = $('.js-show-header-dropdown');
    var sub_menu_is_showed = -1;

    for(var i=0; i<menu.length; i++){
        $(menu[i]).on('click', function(){ 
            
                if(jQuery.inArray( this, menu ) == sub_menu_is_showed){
                    $(this).parent().find('.header-dropdown').toggleClass('show-header-dropdown');
                    sub_menu_is_showed = -1;
                }
                else {
                    for (var i = 0; i < menu.length; i++) {
                        $(menu[i]).parent().find('.header-dropdown').removeClass("show-header-dropdown");
                    }

                    $(this).parent().find('.header-dropdown').toggleClass('show-header-dropdown');
                    sub_menu_is_showed = jQuery.inArray( this, menu );
                }
        });
    }

    $(".js-show-header-dropdown, .header-dropdown").click(function(event){
        event.stopPropagation();
    });

    $(window).on("click", function(){
        for (var i = 0; i < menu.length; i++) {
            $(menu[i]).parent().find('.header-dropdown').removeClass("show-header-dropdown");
        }
        sub_menu_is_showed = -1;
    });


     /*[ Fixed Header ]
    ===========================================================*/
    var posWrapHeader = $('.topbar').height();
    var header = $('.container-menu-header');
    var mobheader = $('.wrap_header_mobile');
    var mobsidemenu = $('.wrap-side-menu');

    $(window).on('scroll',function(){

        if($(this).scrollTop() >= posWrapHeader) {
            $('.header1').addClass('fixed-header');
            $(header).css('top',-posWrapHeader); 
            $(mobsidemenu).css('top', mobheader.height()); 
        }  
        else {
            var x = - $(this).scrollTop(); 
            $(header).css('top',x); 
            $(mobsidemenu).css('top', mobheader.height()); 
            $('.header1').removeClass('fixed-header');
        } 

        if($(this).scrollTop() >= 200 && $(window).width() > 992) {
            $('.fixed-header2').addClass('show-fixed-header2');
            $('.header2').css('visibility','hidden'); 
            $('.header2').find('.header-dropdown').removeClass("show-header-dropdown");
            
        }  
        else {
            $('.fixed-header2').removeClass('show-fixed-header2');
            $('.header2').css('visibility','visible'); 
            $('.fixed-header2').find('.header-dropdown').removeClass("show-header-dropdown");
        } 

    });
    
    /*[ Show menu mobile ]
    ===========================================================*/
    $('.btn-show-menu-mobile').on('click', function(){
        $(this).toggleClass('is-active');
        $('.wrap-side-menu').slideToggle();
    });

    $('.wrap-side-menu a').click(function(e) {   
        $('.wrap-side-menu').slideToggle();
        $('.btn-show-menu-mobile').toggleClass("is-active");
    });

    var arrowMainMenu = $('.arrow-main-menu');

    for(var i=0; i<arrowMainMenu.length; i++){
        $(arrowMainMenu[i]).on('click', function(){
            $(this).parent().find('.sub-menu').slideToggle();
            $(this).toggleClass('turn-arrow');
        })
    }

    $(window).resize(function(){
        if($(window).width() >= 992){
            if($('.wrap-side-menu').css('display') == 'block'){
                $('.wrap-side-menu').css('display','none');
                $('.btn-show-menu-mobile').toggleClass('is-active');
            }
            if($('.sub-menu').css('display') == 'block'){
                $('.sub-menu').css('display','none');
                $('.arrow-main-menu').removeClass('turn-arrow');
            }
        }
    });


    /*[ remove top noti ]
    ===========================================================*/
    $('.btn-romove-top-noti').on('click', function(){
        $(this).parent().remove();
    })


    /*[ Block2 button wishlist ]
    ===========================================================*/
    $('.block2-btn-addwishlist').on('click', function(e){
        e.preventDefault();
        $(this).addClass('block2-btn-towishlist');
        $(this).removeClass('block2-btn-addwishlist');
        $(this).off('click');
    });

    /*[ +/- num product ]
    ===========================================================*/
    $('.btn-num-product-down').on('click', function(e){
        e.preventDefault();
        var numProduct = Number($(this).next().val());
        if(numProduct > 1) $(this).next().val(numProduct - 1);
    });

    $('.btn-num-product-up').on('click', function(e){
        e.preventDefault();
        var numProduct = Number($(this).prev().val());
        $(this).prev().val(numProduct + 1);
    });


    /*[ Show content Product detail ]
    ===========================================================*/
    $('.active-dropdown-content .js-toggle-dropdown-content').toggleClass('show-dropdown-content');
    $('.active-dropdown-content .dropdown-content').slideToggle('fast');

    $('.js-toggle-dropdown-content').on('click', function(){
        $(this).toggleClass('show-dropdown-content');
        $(this).parent().find('.dropdown-content').slideToggle('fast');
    });


    /*[ Play video 01]
    ===========================================================*/
    var srcOld = $('.video-mo-01').children('iframe').attr('src');

    $('[data-target="#modal-video-01"]').on('click',function(){
        $('.video-mo-01').children('iframe')[0].src += "&autoplay=1";

        setTimeout(function(){
            $('.video-mo-01').css('opacity','1');
        },300);      
    });

    $('[data-dismiss="modal"]').on('click',function(){
        $('.video-mo-01').children('iframe')[0].src = srcOld;
        $('.video-mo-01').css('opacity','0');
    });


    $("a[href^='#']").on("click", function (e) {
        let id = $(this).attr("href");
        const $target = $(id);
    

    });

    $(document).on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();
        let id = $(this).attr("href");
        scrollToCustom(id);
    });

    // https://github.com/dkern/jquery.lazy/issues/98
    function scrollToCustom(id) {
        const $target = $(id);
        let topbarHeight = $('.header1').outerHeight();
        const wrapHeaderMobileVisible = $('.wrap_header_mobile').is(':visible');
    
        if (wrapHeaderMobileVisible) {
            topbarHeight = ($('.wrap_header_mobile').outerHeight()); // Jei matomas, nenaudojame topbarHeight
        }
    
        if ($target.length > 0) {
            $('html, body').stop().animate({
                scrollTop: $target.offset().top - topbarHeight
            }, {
                duration: 800,
                step: (now, fx) => {
                    let realPos = $target.offset().top - topbarHeight;
                    if (fx.end !== realPos) {
                        fx.end = realPos;
                    }
                },
            });
    
            if (window.history && window.history.pushState) {
                history.pushState("", document.title, id);
            }
        }
    }

})(jQuery);